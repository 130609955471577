<template>
	<v-app id="pui9app">
		<pui-cookies-bar v-if="!(showResetPassword && !isLdapActive)" />
		<pui-user-reset-password v-if="showResetPassword && !isLdapActive" />
		<pui-login-layout-mobile v-else-if="isMobile">
			<template slot="puiLoginEndActions">
				<pui-development-environment class="pui-development-environment" />
				<pui-user-forget-password v-if="!isLdapActive" class="pui-user-forget-password" />
			</template>
			<template slot="puiLoginAppActions">
				<component v-if="appLoginActions" :is="appLoginActions"></component>
			</template>
		</pui-login-layout-mobile>
		<pui-login-layout-desktop v-else>
			<template slot="puiLoginEndActions">
				<pui-development-environment class="pui-development-environment" />
				<pui-user-forget-password v-if="!isLdapActive" class="pui-user-forget-password" />
			</template>
			<template slot="puiLoginAppActions">
				<component v-if="appLoginActions" :is="appLoginActions"></component>
			</template>
			<template slot="puiLoginDisplayArea">
				<component v-if="appLoginDisplayArea" :is="appLoginDisplayArea"></component>
			</template>
		</pui-login-layout-desktop>
	</v-app>
</template>

<script>
import constants from '@/utils/constants';
import AppLoginActions from './AppLoginActions';

export default {
	name: 'PuiIntroLayout',
	components: {
		AppLoginActions
	},
	data() {
		return {
			isMobile: this.$store.getters.isMobile,
			appLoginDisplayArea: null,
			appLoginActions: null
		};
	},
	computed: {
		showResetPassword() {
			return this.$store.state.login.forgetPassword && window.location.href.includes('resetPassword');
		},
		isLdapActive() {
			return this.$store.getters.isLdapActive;
		}
	},
	created() {
		console.log('title', document.title);
		if (document.title == 'Loading...') document.title = 'Login';
		this.$store.dispatch('puiIsLdapActive', {});
		var recover = this.puiLoginRecoverSession();
		if (recover) {
			if (this.$store.getters.getToken && (!this.$store.state.session.use2fa || this.$store.state.login.validated2fa)) {
				if (!this.$route.query.redirect) {
					this.$store.dispatch('puiLoginGoToDefaultLayoutPage', { router: this.$router, route: this.$route });
				} else {
					this.$store.dispatch('puiLoginRedirectAfterLogin', { router: this.$router, route: this.$route });
				}
			}
		} else if (this.$route.query.redirect !== '/resetPassword') {
			this.$store.dispatch('puiLoginRedirectAfterLogin', { router: this.$router, route: this.$route });
		}

		if (this.$store.state.global.appLoginDisplayArea) {
			this.appLoginDisplayArea = 'applogindisplayarea';
		}
		if (this.$store.state.global.appLoginActions) {
			this.appLoginActions = 'app-login-actions';
		}
	},
	mounted() {
		//localStorage.removeItem("vuex");
		var login = document.getElementById('puilogin');
		var buton = document.getElementById('submitLogin');
		var divButt = document.createElement('div');
		var divButt2 = document.createElement('div');
		// LOGO STRUCTURE
		var divLogo = document.getElementsByClassName('login__logo')[0];
		var logo = document.createElement('i');
		var logoNameDiv = document.createElement('div');
		var logoFirstWord = document.createElement('span');
		var appNameWords = constants.APPLICATION_NAME.split(' ');

		divLogo.classList.add('d-flex', 'justify-sm-start', 'justify-center', 'align-center');
		logo.classList.add('fs-app-logo', /* 'mr-2' ,*/ 'fak', constants.APPLICATION_CLASS);

		//console.log('debug login', appNameWords, appNameWords.length);
		if (appNameWords.length == 2) {
			var logoSecondWord = document.createElement('span');
			logoNameDiv.classList.add('d-flex', 'flex-column');

			logoFirstWord.innerHTML = appNameWords[0];
			logoSecondWord.innerHTML = appNameWords[1];

			logoFirstWord.setAttribute('id', 'pui-login__app-name');
			logoSecondWord.setAttribute('id', 'pui-login__app-name--bold');

			logoNameDiv.appendChild(logoFirstWord);
			logoNameDiv.appendChild(logoSecondWord);
		} else {
			logoFirstWord.innerHTML = constants.APPLICATION_NAME;
			logoFirstWord.setAttribute('id', 'pui-login__app-name--bold');
			logoNameDiv.appendChild(logoFirstWord);
		}

		divLogo.appendChild(logo);
		divLogo.appendChild(logoNameDiv);

		divButt.className = 'v-card__actions';
		divButt.appendChild(divButt2);
		divButt2.className = 'contSubmitLogin';
		divButt2.appendChild(buton);

		//login.appendChild(buton);
		login.insertBefore(divButt, login.children[2]);
		this.styleCopernicusDiv();
		console.log('mounted login');
		this.$store.commit('setLayers', {});
	},
	updated() {
		console.log('updated login');
	},
	destroyed() {
		console.log('destroyed login');
	},
	methods: {
		puiLoginRecoverSession() {
			const infoKey = this.$store.state.session.keepSessionAliveInfoKey;
			var alive = window.localStorage.getItem(infoKey);
			if (alive) {
				var savedInfoStr = window.localStorage.getItem(infoKey);
				if (savedInfoStr) {
					const savedInfo = JSON.parse(savedInfoStr);
					this.setLoginInfo(savedInfo);
					this.$puiEvents.$emit('onPuiLoginSignin', savedInfo);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		setLoginInfo(loginInfo) {
			//first commit the login info, the language will be set applying some logic
			this.$store.commit('puiLoginSetInfo', loginInfo);
			//then we use the language saved, not the recieved
			const lang = this.$store.getters.getUserLanguage;
			this.changeAppLang(lang);
			this.$store.commit('setHttpRequestHeaders', { authorization: loginInfo.jwt, acceptLanguage: lang });
		},
		changeAppLang(lang) {
			this.$puiI18n.locale = lang;
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		},
		styleCopernicusDiv() {
			if (document.getElementsByClassName('v-card__actions').length >= 4) {
				document.getElementsByClassName('v-card__actions')[3].id = 'copernicus-credit-container';
			} else {
				document.getElementById('copernicus-credit-login').classList.add('d-none');
			}
		}
	}
};
</script>

<style lang="postcss">
@import '../../styles/eiffel-variables.pcss';

/* .pui-user-forget-password {
	margin: auto;
}
.pui-development-environment {
	margin: auto;
} */

.v-application .headline {
	font-family: var(--font-family-primary) !important;
}
</style>
